import { createSlice } from "@reduxjs/toolkit";
import { fetchPopularProducts, fetchProductData, fetchProductDetailByID, fetchProductsByCategory, fetchProductsByRent, fetchSubProductsByRent } from "../../thunks/Thunks";

const productSlice = createSlice({
  name: "product",
  initialState: {
    data: [],
    filteredData: [],
    rentProductData: [],
    rentSubProductData: [],
    popular:[],
    status: "idle",
    popularstatus: "idle",
    popularerror: null,
    query: '',
    error: null,
  },
  reducers: {
    setSearchQuery(state, action) {
      state.query = action.payload;
      state.filteredData = state.data.filter((item) =>
        item.name.toLowerCase().includes(action.payload.toLowerCase())
      );
      console.log(state.filteredData);
    
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductData.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
        state.filteredData = action.payload;
      })
      .addCase(fetchProductData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      builder
      .addCase(fetchProductsByCategory.fulfilled, (state, action) => {
        state.filteredData = action.payload;
      }) 
      builder
      .addCase(fetchSubProductsByRent.fulfilled, (state, action) => {
        state.rentSubProductData = action.payload;
      }) 
      builder
      .addCase(fetchProductsByRent.fulfilled, (state, action) => {
        state.rentProductData = action.payload;
      }) 
      builder
      .addCase(fetchPopularProducts.pending, (state) => {
        state.popularstatus = "loading";
      })
      .addCase(fetchPopularProducts.fulfilled, (state, action) => {
        state.popularstatus = "success";
        state.popular = action.payload;
    
      })
      .addCase(fetchPopularProducts.rejected, (state, action) => {
        state.popularstatus = "failed";
        state.popularerror = action.error.message;
      })

  },
});

export const { setSearchQuery } = productSlice.actions;
export default productSlice.reducer;
