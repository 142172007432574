import React from 'react';

const ContactButton = ({ phoneNumber }) => {
  const handleClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <button onClick={handleClick} className="btn btn-danger border-red fw-bold m-2">
      Contact Us
    </button>
  );
};

export default ContactButton;
