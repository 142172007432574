import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  BASE_URL,
  productData,
  productdetailData,
} from "../../../../data/Constants";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Button,
  Form,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCartProducts,
  incrementProduct,
  decrementProduct,
  getCartQuantity,
  removeItem,
} from "../../../../features/cart/reducer/CartReducer";
import { IMAGE_BASEURL } from "../../../../data/Constants";
import CurrencyDisplay from "../../../../features/settings/component/Currency";
import { fetchProductDetailByID } from "../../../../features/thunks/Thunks";
import Loader from "../../../../components/loader/Loader";
import { faPlusMinus } from "@fortawesome/free-solid-svg-icons";
//import { handleDecrement, handleIncrement } from "../app/utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  faCartPlus,
  faTrash,
  faMinus,
  faPlus,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import Badge from "react-bootstrap/Badge";
import { fetchProductsByRent } from "../../../thunks/Thunks";

function RentDetailPage() {
  const { id,option} = useParams();
  console.log(id);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewCart = useSelector((state) => state.cart.viewCart);
  const data = useSelector((state) => state.productdetail.data);
  const [price, setPrice] = useState();
  const [status, setStatus] = useState("loading");
  const [selectedOption, setSelectedOption] = useState();
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  console.log(option);
  const Url = option==="nooption"?`${BASE_URL}/products/${id}/`:`${BASE_URL}/product-option/${id}/`;
  console.log(Url);
  const date = new Date();
  //const formatLocalDate = date.toLocaleDateString();
 // const formatSpecified = date.toLocaleDateString("hi-IN");
  //console.log(formatLocalDate);

  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(null);
  const [rentalOption, setRentalOption] = useState("daily"); // Default to weekly

  // Function to handle rental option change
  const handleRentalOptionChange = (option, event) => {
    console.log(option);
    setRentalOption(option);

    // Optionally, you can clear dates or adjust them based on the selected rental option
    if (option === "daily") {
      setPrice(productDetail.rentalrate);

      // Logic for daily rental (if needed)
    } else if (option === "weekly") {
      setPrice(productDetail.weeklyrentalrate);
      // Logic for weekly rental (if needed)
    } else if (option === "monthly") {
      setPrice(productDetail.monthlyrentalrate);
      // Logic for monthly rental (if needed)
    }
  };

  const [productDetail, setProductDetail] = useState({

    id: "",
    make: "",
    model: "",
    rentalrate: "",
    weeklyrentalrate: "",
    monthlyrentalrate: "",
    year: 0,
    price: "",
    mileage: 0,
    image: "",
    type:"",

  });

  useEffect(() => {
    console.log(Url);
  //  dispatch(fetchProductsByRent(name));

    axios
      .get(Url)
      .then((response) => {
        console.log(response.data);
    //const newdata = testdata[0];
 const newdata = response.data;

    console.log(newdata);
    // setProductata(response.data.data);
    setProductDetail({
      ...productDetail,
      id: id,
      productid: id,
      name: option==="nooption"?newdata.name:newdata.option_name,
      productname: newdata.name,
      category: newdata.rent_category_type,
      description: newdata.description,
      rentalrate: newdata.rentalrate,
      weeklyrentalrate: newdata.weeklyrentalrate,
      monthlyrentalrate: newdata.monthlyrentalrate,
      make: newdata.make,
      model: newdata.model,
      year: newdata.year,
      price: newdata.price,
      mileage: newdata.mileage,
      image: newdata.image,
      type:"rent",   
      quantity: 1,
      subtotal: parseFloat(1 * newdata.price),
    });

    setSelectedOption(id);
    setPrice(newdata.rentalrate);

    setStatus("success");
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setStatus("failed");
            console.error(
              "Network error: Please check your internet connection."
            );
          } else {
            setStatus("failed");
            console.error("Error:", error.message);
          }
        });
  }, [id]);
  console.log(productDetail);
  const addtoCart = (event) => {
    event.preventDefault();
    // const opt = productDetail.find((opt) => opt.id == selectedOption);

    console.log(rentalOption);
    const newProduct = {
      type:productDetail.type,
      _id: rentalOption+productDetail.id,
      productid: rentalOption+productDetail.id,
      name: productDetail.name,
      productname: productDetail.name,
      category: productDetail.category,
      price:
        rentalOption === "daily"
          ? productDetail.rentalrate
          : rentalOption === "weekly"
          ? productDetail.weeklyrentalrate
          : rentalOption === "monthly"
          ? productDetail.monthlyrentalrate
          : productDetail.rentalrate,
      description: productDetail.description,
      quantity: productDetail.quantity,
      subtotal: parseFloat(
        productDetail.quantity *
          (rentalOption === "daily"
            ? productDetail.rentalrate
            : rentalOption === "weekly"
            ? productDetail.weeklyrentalrate
            : rentalOption === "monthly"
            ? productDetail.monthlyrentalrate
            : productDetail.rentalrate)
      ),
      startDate:startDate?startDate.toLocaleDateString("hi-IN"):"",
      endDate:endDate?endDate.toLocaleDateString("hi-IN"):"",
      image: productDetail.image,
      rentalOption:rentalOption
    };
    console.log(newProduct);

    dispatch(addCartProducts([newProduct]));
  };

  const CartView = () => {
    navigate("/viewcart");
  };
  const getCartQuantity = (productId) => {
    console.log(productId);
    console.log("cart in getcartquantity", viewCart);
    const item = viewCart.find(
      (item) => (  (item._id) === productId)
    );
    console.log(item);
    return item ? item.quantity : 0;
  };

  const handleIncrement = (productId, product) => {
    const item = viewCart.find(
      (item) => (item._id) === (productId)
    );
    const qty = item ? item.quantity : 0;
    dispatch(incrementProduct({ _id: productId, quantity: qty + 1 ,type:product.type}));
  };

  const handleDecrement = (productId, product) => {

    const item = viewCart.find(
      (item) =>(item._id) === (productId)
    );
    console.log(product)
    if (item && item.quantity > 1) {

      const qty = item.quantity - 1;
 
      dispatch(decrementProduct({ _id: productId, quantity: qty ,type:product.type}));
    } else if (item.quantity == 1) {
      const qty = item.quantity - 1;
      console.log(qty)
      dispatch(removeItem({_id:productId,type:product.type}));
    }
  };
  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
  };
  if (status == "loading")
    return (
      <Container className="text-center">
        <Loader loading={status} color="#36d7b7" />{" "}
      </Container>
    );
  if (status == "failed")
    return (
      <Container className="text-center">
        <Loader loading={status} color="#FF0000" /> Error: {status}
      </Container>
    );

  const handleImageLoad = () => {
    setLoading(false); // Once image is loaded, set loading state to false
    setImageLoaded(true); // Set imageLoaded to true
  };
  const handleImageError = () => {
    setLoading(false); // Once image is loaded, set loading state to false
    setImageLoaded(true); // Set imageLoaded to true
  };
  console.log(productDetail);

  return (
    <Container fluid className="p-1 mt-3 ">
      <Card className="border border-light ">
        <Row className="m-2">
          <Col md={4}>
            {loading == "false" ? (
              <Loader loading={imageLoaded} />
            ) : (
              <Image
                src={IMAGE_BASEURL + productDetail.image} // Correctly accessing properties of productDetail
                className="img-fluid   rounded-border"
                alt={productDetail.image}
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
            )}
          </Col>
          <Col md={8} className="mt-3 text-left">
            <>
              <Row>
                <Col>
                
                  <h4 className=" fw-bolder">{productDetail.name}</h4>
                </Col>
                <Col className="text-end">
                  <div className="fw-bold text-danger me-3">
                    <CurrencyDisplay
                      amount={price === isNaN ? productDetail.price : price}
                      largeFont={true}
                    />
                  </div>
                </Col>
              </Row>

              <p className="card-text">{productDetail.description}</p>
              {productDetail.category==="building"?(<></>):(
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title"> Information</h2>
                  <ul className="list-group list-group-flush">
                  {productDetail.make?
                    (<li className="list-group-item">
                      <strong>Make:</strong> {productDetail.make}
                    </li>):<></>
                   }
                   {productDetail.model?
                    <li className="list-group-item">
                      <strong>Model:</strong> {productDetail.model}
                    </li>:<></>
                    } 
                    {productDetail.year?
                    <li className="list-group-item">
                      <strong>Year:</strong> {productDetail.year}
                    </li>:<></>
                     }
                     {productDetail.mileage?
                    <li className="list-group-item">
                      <strong>Mileage:</strong> {productDetail.mileage} miles
                    </li>:<></>
                    }
                    <li className="list-group-item">
                      <strong>price:</strong>   <CurrencyDisplay
                      amount={price === isNaN ? productDetail.price : price}
                      largeFont={true}
                    />
                    </li>
                  </ul>
                </div>
              </div>
              )
             }

              <>
                {/* {productDetail.map((option) => (
                    <Form.Group key={option.id} controlId={option.id}>
                      <Row>
                        <Col>
                          <Form.Label className="text-dark">
                            {option.name}
                          </Form.Label>
                        </Col>
                        <Col>
                          <Form.Check
                            className="custom-radio"
                            type="radio"
                            id={option.id.toString()}
                            name="radioGroup"
                            defaultChecked
                            checked={selectedOption == option.id.toString()}
                            value={option.id}
                            label={
                              (rentalOption === "daily"
                                ? option.rentalrate
                                : rentalOption === "weekly"
                                ? option.weeklyrentalrate
                                : rentalOption === "monthly"
                                ? option.monthlyrentalrate
                                : option.rentalrate
                  )}
                            onChange={handleSelect}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  ))} */}
                {/* {selectedOption && <p>You selected: {selectedOption}</p>} */}
              </>

              <>
                <Row id="rentoptions">
                  <Col md={8}>
                    <h4 className=" fw-bolder">Rent Options</h4>
                    <ButtonGroup className="mb-3" aria-label="Rental Options">
                      <Button
                        variant={
                          rentalOption === "daily" ? "danger" : "outline-danger"
                        }
                        onClick={(event) =>
                          handleRentalOptionChange("daily", event)
                        }
                      >
                        Daily
                      </Button>
                      <Button
                        variant={
                          rentalOption === "weekly"
                            ? "danger"
                            : "outline-danger"
                        }
                        onClick={(event) =>
                          handleRentalOptionChange("weekly", event)
                        }
                      >
                        Weekly
                      </Button>
                      <Button
                        variant={
                          rentalOption === "monthly"
                            ? "danger"
                            : "outline-danger"
                        }
                        onClick={(event) =>
                          handleRentalOptionChange("monthly", event)
                        }
                      >
                        Monthly
                      </Button>

                      <Button
                        variant={
                          rentalOption === "custom"
                            ? "danger"
                            : "outline-danger"
                        }
                        onClick={() => handleRentalOptionChange("custom")}
                      >
                        Custom
                      </Button>
                    </ButtonGroup>
                    <div className="mt-3">
                      <h5>Selected Rental Option:</h5>
                      <p className="fw-bold">
                        {rentalOption.charAt(0).toUpperCase() +
                          rentalOption.slice(1)}
                      </p>
                      <h5>Price:</h5>
                      <p className="fw-bold">
                      <CurrencyDisplay
                      amount={price === isNaN ? price : price}
                      largeFont={true}
                    />
                        {/* {price === "Custom" ? price : `$${price}`} */}
                      </p>
                    </div>
                  </Col>
                </Row>
                {rentalOption === "custom" ? (
                  <Form>
                    <Form.Group controlId="startDate">
                      <Form.Label>Start Date</Form.Label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="form-control"
                        dateFormat="dd/MM/yyyy" // Correct date format
                        placeholderText="Select start date"
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        isClearable
                      />
                    </Form.Group>

                    <Form.Group controlId="endDate" className="mt-2">
                      <Form.Label>End Date</Form.Label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="form-control"
                        dateFormat="dd/MM/yyyy" // Correct date format
                        placeholderText="Select end date"
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        isClearable
                      />
                    </Form.Group>
                  </Form>
                ) : (
                  <></>
                )}
              </>

              {getCartQuantity(rentalOption+productDetail.productid) === 0 ? (
                <div>
                  <div>
                    <button
                      className="btn btn-outline-danger fw-bold m-2"
                      onClick={addtoCart}
                    >
                      Add to cart
                    </button>
                  </div>
                </div>
              ) : (

                <Col lg="auto text-left  ">
                  <div className="m-2 btn d-flex fs-6 fw-bold text-danger border-white text-left">
                    <span className="fs-6 fw-bold text-dark me-3">
                      Quantity
                    </span>
                    <div className="assistant-font icon-wrapper">
                      <FontAwesomeIcon
                        size="sm"
                        icon={faMinus}
                        onClick={() => handleDecrement(rentalOption+productDetail.productid,productDetail)}
                        className="custom-icon"
                      />
                    </div>

                    <span
                      bg="danger"
                      className="mx-2 assistant-font fs-5 fw-bold mb-2"
                    >
                      {getCartQuantity(rentalOption+
                  productDetail.productid
                      )}
                    </span>
                    <div className="assistant-font icon-wrapper">
                      <FontAwesomeIcon
                        size="sm"
                        className="custom-icon"
                        icon={faPlus}
                        onClick={() => handleIncrement(rentalOption+productDetail.productid,productDetail)}
                      />
                    </div>
                  </div>
                </Col>
              )}
              <div>
                <button
                  className="btn btn-danger border-red fw-bold m-2"
                  type="button"
                  onClick={() => CartView()}
                >
                  View Cart
                </button>
              </div>
            </>
          </Col>
        </Row>
      </Card>
      <Container className="mt-4 p-3 "></Container>
    </Container>
  );
}

export default RentDetailPage;
