import { React, useState,useEffect } from "react";
import Categories from "../features/categories/components/Categories";
import Banner from "../components/banner/Banner";
import Search from "../components/search/Search";
import LocationPickerMap from "../features/map/LocationPickerMap";
import MapComponent from "../components/map/MapComponent";
import PlacesAutoComplete from "../features/map/component/PlacesAutoComplete";
import { Container } from "react-bootstrap";
import Footer from "../components/footer/Footer";
import { useDispatch } from "react-redux";
import { fetchSettingsApi } from "../features/thunks/Thunks";
import "../styles/Custom.css"
import Services from "../features/categories/components/Services";



function HomePage() {
  //search state
  const [searchTerm, setSearchTerm] = useState("");


  return (
    <Container fluid className="g-0 m-0 p-0 ">
      <Search onSearch={setSearchTerm} />
       <Banner /> 
    {/* <Services searchTerm={searchTerm} className="main-content"/>   */}
    <Categories searchTerm={searchTerm} className="main-content"/>   
    </Container>
  );
}

export default HomePage;
