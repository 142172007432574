import React, { useEffect, useState } from "react";
import axios from "axios";
import Products from "../../products/components/Products";
import {
  BASE_URL,
  categoryData,
  categoryEquipmentData,
  categoryServicetData,
  data,
  IMAGE_BASEURL,
  rentalData,
  servicesData,
} from "../../../data/Constants";

import { setSearchQuery } from "../reducer/CategoryReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategoryBuildingServices,
  fetchCategoryBuildingManagement,
  fetchCategoryServices,
  fetchProductData,
  fetchCategoryRent,
} from "../../thunks/Thunks";
import { fetchCategories } from "../../thunks/Thunks";
import { fetchProductsByCategory, fetchSettingsApi } from "../../thunks/Thunks";
import { selectCategory } from "../reducer/CategoryReducer";
import {
  Col,
  Container,
  Image,
  Row,
  Form,
  Label,
  Card,
  Tab,
  Nav,
} from "react-bootstrap";
import Loader from "../../../components/loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "../../../styles/Custom.css";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import defaultImg from "../../../assets/images/defaultimage.jpg";
import { Link } from "react-router-dom";
import Divider from "../../../components/Divider/Divider";
import mainlogo from "../../../assets/images/pizzamodo512.png";
import "../../../styles/Custom.css";

function Categories({ searchTerm }) {
  const dispatch = useDispatch();
  // const productState = useSelector((state) => state.product);
  const categoryState = useSelector((state) => state.category);
  console.log(categoryState);
  //const categoryState = categoryEquipmentData;
  //const categoryServiceState = categoryServicetData;
  //const categoryServiceState =  useSelector((state) => state.fetchCategoryServices);
  //console.log(categoryServiceState);

  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const scrollInterval = 3000; // Set the interval for automatic scrolling in milliseconds

  const [activeKey, setActiveKey] = useState("equipments");
  //  setActiveKey(k)
  //dispatch view all categories and products
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchCategories());
    dispatch(fetchCategoryServices());
    dispatch(fetchCategoryBuildingServices());
    dispatch(fetchCategoryBuildingManagement());
    dispatch(fetchCategoryRent());
    //dispatch(fetchProductData());
  }, [dispatch]);

  // Filter products when the search term changes
  useEffect(() => {
    if (searchTerm) {
      console.log(searchTerm);
      dispatch(setSearchQuery(searchTerm));
    }
  }, [searchTerm]);

  const handleCategoryClick = (category) => {
    dispatch(selectCategory(category.strCategory));
    // dispatch(fetchProductsByCategory(category.id));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const container = document.getElementById("categoryContainer");
      if (container) {
        if (
          container.scrollLeft + container.offsetWidth >=
          container.scrollWidth
        ) {
          container.scrollLeft = 0; // Reset scroll to the start
        } else {
          container.scrollLeft += container.offsetWidth;
        }
      }
    }, scrollInterval);

    return () => clearInterval(intervalId);
  }, []);

  if (categoryState.status == "failed")
    return (
      <Container className="text-center">
        <Loader loading={categoryState.status} color="#FF0000" /> Error:{" "}
        {categoryState.error}
      </Container>
    );

  const renderCategories = () => {
    return categoryState.categories.map((item) => (
      <div className="col-sm-1 col-lg-6 p-1 m-2" key={item.id}>
        <Row>
          <Col>
            <Card className="shadow-sm text-left  rounded-border-product">
              <Link to={`/categorydetail/${item.id}/${item.name}`}>
                <Image
                  src={IMAGE_BASEURL + item.image}
                  alt={defaultImg}
                  className="img-thumbnail mx-auto p-2 border-white rounded-border-product   "
                />
              </Link>

              <Card.Body>
                {" "}
                <Row className="ps-2 ">
                  <Link
                    to={`/categorydetail/${item.id}/${item.name}`}
                    className="text-decoration-none"
                  >
                    <Col className="rounded-border-product bg-dark">
                      <span className="assistant-font fw-bold fs-6  m-0 truncate text-white p-2 ">
                        {item.name}
                      </span>
                    </Col>
                  </Link>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    ));
  };

  const renderServiceCategories = () => {
    return categoryState.categoryservices.map((item) => (
      <div className="col-sm-1 col-lg-6 p-1 m-2" key={item.id}>
        <Row>
          <Col>
            <Card className="shadow-sm text-left  rounded-border-product">
              <Link to={`servicedetails/${item.id}`}>
                <Image
                  src={IMAGE_BASEURL + item.image}
                  alt={defaultImg}
                  className="img-thumbnail mx-auto p-2 border-white rounded-border-product   "
                />
              </Link>

              <Card.Body>
                {" "}
                <Row className="ps-2 ">
                  <Link
                    to={`/servicedetails/${item.id}`}
                    className="text-decoration-none"
                  >
                    {item.sub_name ? (
                      <Col className="rounded-border-product bg-dark">
                        <span className="assistant-font fw-bold fs-6  m-0 truncate   text-white p-2 ">
                          {item.name}
                        </span>
                      </Col>
                    ) : (
                      <Col className="rounded-border-product bg-dark">
                        <>
                          {" "}
                          <span className="assistant-font fw-bold fs-6  m-0 truncate   text-white p-2">
                            {item.name}
                          </span>
                        </>
                      </Col>
                    )}
                  </Link>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    ));
  };
  const renderBuildingCategories = () => {
    return categoryState.categorybuildingservices.map((item) => (
      <div className="col-sm-1 col-lg-6 p-1 m-2" key={item.id}>
        <Row>
          <Col>
            <Card className="shadow-sm text-left  rounded-border-product">
              <Link to={`materialdetails/${item.id}`}>
                <Image
                  src={IMAGE_BASEURL + item.image}
                  alt={defaultImg}
                  className=" w-100 img-thumbnail mx-auto p-2 border-white rounded-border-product   "
                />
              </Link>

              <Card.Body>
                {" "}
                <Row className="ps-2 ">
                  <Link
                    to={`/materialdetails/${item.id}`}
                    className="text-decoration-none"
                  >
                    <Col className="rounded-border-product bg-dark">
                      <span className="assistant-font fw-bold fs-6  m-1 truncate text-center d-flex justify-content-center align-items-center  text-white p-0">
                        {item.name}
                      </span>
                    </Col>
                  </Link>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    ));
  };
  const renderRentCategories = () => {
    return rentalData.map((item) => (
      <div className="col-sm-1 col-lg-6 p-1 m-2" key={item.id}>
        <Row>
          <Col>
            <Card className="shadow-sm text-left  rounded-border-product">
              <Link
                to={`/rentproductsbycategory/${item.id}/${item.sub_name}`}
              >
                <Image
                  src={ item.image}
                  alt={defaultImg}
                  className=" w-100 img-thumbnail mx-auto p-2 border-white rounded-border-product   "
                />
              </Link>

              <Card.Body>
                {" "}
                <Row className="ps-2 ">
                  <Link
                    to={`/rentproductsbycategory/${item.id}/${item.sub_name}`}
                    className="text-decoration-none"
                  >
                    <Col className="rounded-border-product bg-dark">
                      <span className="assistant-font fw-bold fs-6  m-1 truncate text-center d-flex justify-content-center align-items-center  text-white p-0">
                        {item.name}
                      </span>
                    </Col>
                  </Link>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    ));
  };


  const renderBuildingManagement = () => {
    return categoryState.categorybuildingmanagement.map((item) => (
      <div className="col-sm-1 col-lg-6 p-1 m-2" key={item.id}>
        <Row>
          <Col>
            <Card className="shadow-sm text-left  rounded-border-product">
              <Link
                to={`/buildingdetails/${item.id}/${encodeURIComponent(
                  JSON.stringify(item)
                )}`}
              >
                <Image
                  src={IMAGE_BASEURL + item.image}
                  alt={defaultImg}
                  className=" w-100 img-thumbnail mx-auto p-2 border-white rounded-border-product   "
                />
              </Link>

              <Card.Body>
                {" "}
                <Row className="ps-2 ">
                  <Link
                    to={`/buildingdetails/${item.id}/${encodeURIComponent(
                      JSON.stringify(item)
                    )}`}
                    className="text-decoration-none"
                  >
                    <Col className="rounded-border-product bg-dark">
                      <span className="assistant-font fw-bold fs-6  m-1 truncate text-center d-flex justify-content-center align-items-center  text-white p-0">
                        {item.name}
                      </span>
                    </Col>
                  </Link>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    ));
  };

  return (
    <Container fluid className="mt-1 mb-5">
      <Row className="text-center">
        <Divider text="CATEGORIES" />
      </Row>
      <Row className="justify-content-center">
        <Col md={12}>
          <Tab.Container
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k)}
          >
            <Row className="mb-3">
              <Col>
                <div
                  style={{
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                  }}
                >
                  <ScrollMenu>
                    <Nav
                      variant="pills"
                      className="flex-nowrap"
                      style={{ display: "flex" }}
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="equipments">Equipments</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="services">Services</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="buildingmaterials">
                          Building Materials
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="rent">Rent</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="buildingmanagement">
                          Building Management
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </ScrollMenu>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                <Tab.Content>
                  <Tab.Pane eventKey="equipments">
                    <div className="row g-0 m-4 justify-content-center text-center">
                      {renderCategories()}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="services">
                    <div className="row g-0 m-4 w-10 justify-content-center text-center">
                      {renderServiceCategories()}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="buildingmaterials">
                    <div className="row g-0 m-4 justify-content-center text-center">
                      {renderBuildingCategories()}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="rent">
                    <div className="row g-0 m-4 w-10 justify-content-center text-center">
                      {renderRentCategories()}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="buildingmanagement">
                    <div className="row g-0 m-4 justify-content-center text-center">
                      {renderBuildingManagement()}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
      <Container className="mt-4 p-3"></Container>
    </Container>
  );
}
export default Categories;
