import { React, useState, useEffect } from "react";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faTrash,
  faMinus,
  faPlus,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

import { useDispatch, useSelector } from "react-redux";
import Badge from "react-bootstrap/Badge";
import { IMAGE_BASEURL, productData } from "../../../data/Constants";
import CurrencyDisplay from "../../settings/component/Currency";
import Loader from "../../../components/loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../../styles/Custom.css";
import { Image } from "react-bootstrap";
import defaultImg from "../../../assets/images/defaultimage.jpg";


import ContactButton from "../../../components/contact/Contact";

function SaleSubProducts() {

  const { id, product } = useParams();
  //list options  using same products api by filtering options array
  const decodedProduct = JSON.parse(decodeURIComponent(product));
  console.log(decodedProduct);

  console.log(id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <div className="container-fluid  g-0 ">
      <div className="row g-0 mt-4">
        {decodedProduct.options.map((product) => (
          <div className="col-md-3 col-6 p-2   " key={product.id}>
            <Card className="shadow-sm text-left rounded-border-product    ">
              <Link to={`/productoptiondetail/${product.id}/${product.option_name}`}>
                <Image
                  src={IMAGE_BASEURL + product.image}
                  alt={defaultImg}
                  className="img-fluid mx-auto p-2 border-white rounded-border-product   "
                />
              </Link>

              <Card.Body className="m-0 p-0 pb-2 ">
                <Row className="ps-2 ">
                  <Col>
                    <span className="assistant-font fw-bold fs-6  m-0 truncate ">
                      {product.option_name}
                    </span>
                    {/* <CurrencyDisplay amount={product.price} /> */}
                  </Col>
                </Row>

                <Link
                  to={`/productoptiondetail/${product.id}/${product.option_name}`}
                  className="text-decoration-none"
                >
                  <Button variant="btn btn-outline-danger fs-6 fw-bold m-1 ">
                    view
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SaleSubProducts;
