import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Row, Col, Button, Form } from "react-bootstrap";
import "../styles/Custom.css";
import { Link } from "react-router-dom";
import CurrencyDisplay from "../features/settings/component/Currency";
import { IMAGE_BASEURL } from "../data/Constants";
import { grandTotal } from "../features/cart/reducer/CartReducer";
import { fetchSettingsApi } from "../features/thunks/Thunks";

function OrderSummaryPage() {
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state.cart);
  const settingdetails = useSelector((state) => state.settings.settingdetails);
  const [option, setSelectedOption] = useState("delivery");
  useEffect(() => {
    dispatch(fetchSettingsApi());
  }, [dispatch]);
  //calculate total price of products
  const calculateTotalPrice = () => {
    console.log(cartState.total);
    const tot = parseFloat(
      cartState.viewCart.reduce(
        (total, item) => total + parseFloat(item.subtotal),
        0
      )
    ).toFixed(3);
    return tot;
  };
  //calculate vat after applying to product
  const calculateVATPercent = (totalPrice) => {
    console.log(
      settingdetails.tax_percentage ? settingdetails.tax_percentage : 5
    );
    const tot = (
      (calculateTotalPrice() *
        parseFloat(
          settingdetails.tax_percentage ? settingdetails.tax_percentage : 5
        )) /
      100
    ).toFixed(3);
    return tot;
  };
  //calculate grand total with total and vat and delivery cost
  const calculateGrandTotal = () => {
    var grandtotal;
    if(option=="delivery"){
     grandtotal = parseFloat(
      parseFloat(calculateTotalPrice() - calculateVATPercent()) +
        parseFloat(calculateVATPercent()) +
        parseFloat(settingdetails.delivery_cost)
    ).toFixed(3);
  }else{
     grandtotal = parseFloat(
      parseFloat(calculateTotalPrice() - calculateVATPercent()) +
        parseFloat(calculateVATPercent()) 
    
    ).toFixed(3);
  }
    dispatch(grandTotal(grandtotal));
    return grandtotal;
  };

  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
    const deliveryRow = document.getElementById("deliveryRow");
    if (e.target.value == "takeaway" || e.target.value == "dinein") {
      if (deliveryRow) {
        deliveryRow.style.display = "none";
      }
    } else {
      deliveryRow.style.display = "flex";
    }
  };

  return (
    <Container className="my-5 assistant-font">
      <h2 className="text-center mb-4 fw-bold">Order Summary</h2>
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="mb-4 shadow-sm">
            <Card.Body>
              {cartState.viewCart.map((item) => (
                <Row key={item._id} className="align-items-center mb-3">
                  <Col xs={3}>
                    <img
                      src={IMAGE_BASEURL+item.image}
                      className="img-fluid rounded fw-bold"
                      alt={item.name}
                    />
                  </Col>
                  <Col xs={9}>
                    <Row>
                      <Col xs={8} className="">
                        {item.has_extra_option === true ? (
                          <div>
                            <h6 className=" fw-bold  ">{item.productname}</h6>
                            <h6 className=" fs-6  ">{item.name}</h6>
                          </div>
                        ) : (
                          <h6 className=" fs-6 fw-bold  ">{item.name}</h6>
                        )}

                        <p className="mb-0 fw-bold fs-6  ">
                          {item.quantity} x {item.price}
                        </p>
                      </Col>
                      <Col
                        xs={4}
                        className="assistant-font"
                        style={{ "text-align": "right" }}
                      >
                        <div className="fw-bold mb-0 text-danger fs-6 text-right pe-2">
                          <CurrencyDisplay
                            amount={item.subtotal}
                            currencySymbol="INR"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </Card.Body>
          </Card>
          <Card className="shadow-sm pe-2">
            <Card.Body>
              <Row className="mb-2 ">
                <Col className="fw-bold  fs-6" xs={8}>
                  Total Before VAT
                </Col>
                <Col xs={4} className="text-end">
                  <div className="text-danger fs-6">
                    {" "}
                    <CurrencyDisplay
                      amount={calculateTotalPrice() - calculateVATPercent()}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="fw-bold ">
                <Col className="fw-bold  fs-6" xs={8}>
                  VAT Incl.(
                  {settingdetails.tax_percentage
                    ? settingdetails.tax_percentage
                    : 5}
                  )%
                </Col>
                <Col xs={4} className="text-end text-danger fs-6">
                  <div>
                    {" "}
                    <CurrencyDisplay amount={calculateVATPercent()} />
                  </div>
                </Col>
              </Row>

              <hr />
              <Row>
                <div class="delivery-options d-none">
                  <Col className="fw-bold  fs-6" xs={8}>
                    Delivery Option
                  </Col>
                  <Row>
                    <Col className="fw-bold">
                      <Form.Check
                        className="custom-radio2"
                        type="radio"
                        label="Delivery"
                        name="fulfillment-option"
                        value="delivery"
                        data-fee="5.00"
                        checked={option === 'delivery'}

                        onChange={handleSelect}
                      />
                    </Col>
                    <Col className="fw-bold">
                      <Form.Check
                        className="custom-radio2"
                        type="radio"
                        label="Takeaway"
                        name="fulfillment-option"
                        value="takeaway"
                        data-fee="0.00"
                        checked={option === 'takeaway'}
                        onChange={handleSelect}
                      />
                    </Col>
                    <Col className="fw-bold">
                      <Form.Check
                         className="custom-radio2"
                        type="radio"
                        label="Dine In"
                        name="fulfillment-option"
                        value="dinein"
                        data-fee="0.00"
                        checked={option === 'dinein'}
                        onChange={handleSelect}
                      />
                    </Col>
                  </Row>
                </div>
              </Row>
              <hr />
              <Row className="mb-2 " id="deliveryRow">
                <Col className="fw-bold  fs-6" xs={8}>
                  Delivery Charge
                </Col>
                <Col xs={4} className="text-end text-danger fs-6">
                  <div>
                    {" "}
                    <CurrencyDisplay amount={settingdetails.delivery_cost} />
                  </div>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="fw-bold  fs-6" xs={8}>
                  Grand Total
                </Col>
                <Col xs={4} className="text-end text-danger fs-6">
                  <div>
                    {" "}
                    <CurrencyDisplay amount={calculateGrandTotal()} />
                  </div>
                </Col>
              </Row>
              <Link to={`/adddetail/${option}`}>
                <Button variant="danger" className="mt-3 w-100 btn btn-danger border-red">
                  Proceed to Checkout
                </Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Container className="mt-4 p-3 "></Container>
    </Container>
  );
}

export default OrderSummaryPage;
