import { createSlice } from "@reduxjs/toolkit";
import { fetchCategories,fetchCategoryRent, fetchCategoryBuildingManagement, fetchCategoryBuildingServices, fetchCategoryServices } from "../../thunks/Thunks";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    selectedCategory: null,
    categories: [],
    filteredData: [],
    categoryservices: [],
    categorybuildingservices: [],
    categorybuildingmanagement: [],
    categoryRent: [],
    status: "idle",
    error: null,
    servicestatus: "idle",
    serviceerror: null,
    buidingservicestatus: "idle",
    buildingserviceerror: null,
    buidingmanagementstatus: "idle",
    buildingmanagementerror: null,
    categoryrentstatus: "idle",
    categoryrenterror: null,
  },
  reducers: {
      setSearchQuery(state, action) {
      state.query = action.payload;
      state.categories = [...state.categories.filter((item) =>
        item.name.toLowerCase().includes(action.payload.toLowerCase())
      ),
      ...state.categoryservices.filter((item) =>
        item.name.toLowerCase().includes(action.payload.toLowerCase())
      ),
      ...state.categorybuildingservices.filter((item) =>
        item.name.toLowerCase().includes(action.payload.toLowerCase())
      ),]
      console.log(state.categories);
    
    },
    selectCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "success";
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
      builder
      .addCase(fetchCategoryServices.pending, (state) => {
        state.servicestatus = "loading";
      })
      .addCase(fetchCategoryServices.fulfilled, (state, action) => {
        state.servicestatus = "success";
        state.categoryservices = action.payload;
      })
      .addCase(fetchCategoryServices.rejected, (state, action) => {
        state.servicestatus = "failed";
        state.serviceerror = action.error.message;
      });
      builder
      .addCase(fetchCategoryBuildingServices.pending, (state) => {
        state.buidingservicestatus = "loading";
      })
      .addCase(fetchCategoryBuildingServices.fulfilled, (state, action) => {
        state.buidingservicestatus = "success";
        state.categorybuildingservices = action.payload;
      })
      .addCase(fetchCategoryBuildingServices.rejected, (state, action) => {
        state.buidingservicestatus = "failed";
        state.buildingserviceerror = action.error.message;
      });
      builder
      .addCase(fetchCategoryBuildingManagement.pending, (state) => {
        state.buidingmanagementstatus = "loading";
      })
      .addCase(fetchCategoryBuildingManagement.fulfilled, (state, action) => {
        state.buidingmanagementstatus = "success";
        state.categorybuildingmanagement = action.payload;
      })
      .addCase(fetchCategoryBuildingManagement.rejected, (state, action) => {
        state.buidingmanagementstatus = "failed";
        state.buildingmanagementerror = action.error.message;
      });
      builder
      .addCase(fetchCategoryRent.pending, (state) => {
        state.categoryrentstatus = "loading";
      })
      .addCase(fetchCategoryRent.fulfilled, (state, action) => {
        state.categoryrentstatus = "success";
        state.categoryRent = action.payload;
      })
      .addCase(fetchCategoryRent.rejected, (state, action) => {
        state.categoryrentstatus = "failed";
        state.categoryrenterror = action.error.message;
      });
  },
});

export const { selectCategory ,setSearchQuery} = categorySlice.actions;
export default categorySlice.reducer;
