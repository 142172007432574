import { React, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  BASE_URL,
  categoryServicetData,
  IMAGE_BASEURL,
  productData,
  productdetailData,
} from "../../../../data/Constants";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Button,
  Form,
  Dropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCartProducts,
  incrementProduct,
  decrementProduct,
  getCartQuantity,
  removeItem,
} from "../../../cart/reducer/CartReducer";

import CurrencyDisplay from "../../../settings/component/Currency";

import Loader from "../../../../components/loader/Loader";
import { fetchCategoryServicesDetail } from "../../../thunks/Thunks";
import ContactButton from "../../../../components/contact/Contact";

function BuildingDetails() {
  const { id, item } = useParams();
  console.log(id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewCart = useSelector((state) => state.cart.viewCart);
  const data = useSelector((state) => state.productdetail.data);

  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const Url = `${BASE_URL}/buildingmanagement/`;
  console.log(Url);
  const newdata = JSON.parse(decodeURIComponent(item));
  console.log(newdata);

  const [productDetail, setProductDetail] = useState({
    id: 0,
    name: "",
    sub_name: "",
    description: "",
    total_price: "",
  });
  useEffect(() => {
    setProductDetail({
      ...productDetail,
      id: newdata.id,
      name: newdata.name,
      sub_name: newdata.sub_name,
      description: newdata.description,
      image: newdata.image,
      total_price: newdata.total_price,
    });
  }, [id]);

  const CartView = () => {
    navigate("/viewcart");
  };

  //   if (status == "loading")
  //     return (
  //       <Container className="text-center">
  //         <Loader loading={status} color="#36d7b7" />{" "}
  //       </Container>
  //     );
  //   if (status == "failed")
  //     return (
  //       <Container className="text-center">
  //         <Loader loading={status} color="#FF0000" /> Error: {status}
  //       </Container>
  //     );

  const handleImageLoad = () => {
    setLoading(false); // Once image is loaded, set loading state to false
    setImageLoaded(true); // Set imageLoaded to true
  };
  const handleImageError = () => {
    setLoading(false); // Once image is loaded, set loading state to false
    setImageLoaded(true); // Set imageLoaded to true
  };
  console.log(productDetail);

  return (
    <Container fluid className="p-1 mt-3 ">
      <Card className="border border-light ">
        <Row className="m-2">
          <Col md={4}>
            {loading == "false" ? (
              <Loader loading={imageLoaded} />
            ) : (
              <Image
                src={IMAGE_BASEURL + productDetail.image} // Correctly accessing properties of productDetail
                className="img-fluid   rounded-border w-100"
                alt={productDetail.image}
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
            )}
          </Col>
          <Col md={8} className="mt-3 text-left">
            <>
              <Row>
                <Col
                  md={
                    productDetail.sub_name && productDetail.sub_name.length > 10
                      ? 12
                      : 8
                  } // Adjust column size based on condition
                  className={
                    productDetail.sub_name && productDetail.sub_name.length > 10
                      ? "mb-2"
                      : ""
                  }
                >
                  <h4 className="fw-bolder">{productDetail.name}</h4>
                  {productDetail.sub_name.length > 10 && (
                    <h6 className="fw-bolder">{productDetail.sub_name}</h6>
                  )}
                </Col>
                {/* <Col>
                  <h4 className=" fw-bolder">{productDetail.name}</h4>
                </Col> */}
                <Col className="text-end">
                  <div className="fw-bold text-danger me-3">
                    <CurrencyDisplay
                      amount={productDetail.total_price}
                      largeFont={true}
                    />
                  </div>
                </Col>
              </Row>
              <p className="card-text">{productDetail.description}</p>

              <div>
                <ContactButton tel="+968 9519 5060" />
              </div>
            </>
          </Col>
        </Row>
      </Card>
      <Container className="mt-4 p-3 "></Container>
    </Container>
  );
}

export default BuildingDetails;
