import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL, productData, productdetailData } from "../../../data/Constants";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Button,
  Form,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCartProducts,
  incrementProduct,
  decrementProduct,
  getCartQuantity,
  removeItem,
} from "../../cart/reducer/CartReducer";
import { IMAGE_BASEURL } from "../../../data/Constants";
import CurrencyDisplay from "../../settings/component/Currency";
import { fetchProductDetailByID } from "../../thunks/Thunks";
import Loader from "../../../components/loader/Loader";
import { faPlusMinus } from "@fortawesome/free-solid-svg-icons";
//import { handleDecrement, handleIncrement } from "../app/utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  faCartPlus,
  faTrash,
  faMinus,
  faPlus,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import Badge from "react-bootstrap/Badge";

function ProductDetailPage() {
  const { id,name } = useParams();
  console.log(id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewCart = useSelector((state) => state.cart.viewCart);
  const data = useSelector((state) => state.productdetail.data);
  const [status, setStatus] = useState("loading");
  const [selectedOption, setSelectedOption] = useState();
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const Url = `${BASE_URL}/products/${id}/`;
  console.log(Url);
  const date = new Date();
  //const formatLocalDate = date.toLocaleDateString();
  //const formatSpecified = date.toLocaleDateString("hi-IN");
  //console.log(formatLocalDate);

  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(null);
  const [rentalOption, setRentalOption] = useState("daily"); // Default to weekly

  // Function to handle rental option change
  const handleRentalOptionChange = (option,event) => {
    setRentalOption(option);
  

    // Optionally, you can clear dates or adjust them based on the selected rental option
    if (option === "daily") {
    
      // Logic for daily rental (if needed)
    } else if (option === "weekly") {
      // Logic for weekly rental (if needed)
    } else if (option === "monthly") {
      // Logic for monthly rental (if needed)
    }
  };

  const [productDetail, setProductDetail] = useState({
    id: 0,
    productid: 0,
    category: "",
    options: [
      {
        id: 0,
        option_name: "",
        additional_price: "",
        rentalrate:"",
        weeklyrentalrate:"",
        monthlyrentalrate:"",
        stock_quantity: 0,
        product: 0,
      },
    ],
    name: "",
    productname: "",
    description: "",
    price: "",
    image: "",
    has_extra_option: true,
    type:"",
  });
  //useeffect fetch product based on the id from useparams
  // const detail =productData.filter(product => product.id === parseInt(id));
  // console.log(detail[0]);
  // const newdata = detail[0];
  useEffect(() => {
    console.log(Url);
    dispatch(fetchProductDetailByID(id));

    axios
      .get(Url)
      .then((response) => {
        console.log(response.data);
        const newdata = response.data;
        // const newdata = productdetailData;

        console.log(newdata);
        // setProductata(response.data.data);
        setProductDetail({
          ...productDetail,
          id: newdata.id,
          productid: newdata.id,
          name: newdata.name,
          productname: newdata.name,
          category: JSON.stringify(newdata.category),
          description: newdata.description,
          image: newdata.image,
          price: newdata.price,
          quantity: 1,
          subtotal: parseFloat(1 * newdata.price),
          has_extra_option: newdata.has_extra_option,
          options: newdata.options,
          type:newdata.product_type,
        });

        // setSelectedOption(
        //   newdata.has_extra_option == true ? newdata.options[0].id : newdata.id
        // );
        setSelectedOption(id);

        setStatus("success");
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setStatus("failed");
          console.error(
            "Network error: Please check your internet connection."
          );
        } else {
          setStatus("failed");
          console.error("Error:", error.message);
        }
      });
  }, [id]);
  console.log(productDetail);
  const addtoCart = (event) => {
    event.preventDefault();
    const opt = productDetail.options.find((opt) => opt.id == selectedOption);
    var extra_option = productDetail.has_extra_option == true;
    
    console.log(rentalOption);
    const newProduct = {
      _id: extra_option ? selectedOption : productDetail.id,
      productid: productDetail.id,
      name: extra_option ? opt.option_name : productDetail.name,
      productname: productDetail.name,
      category: productDetail.category,
      price: extra_option ? (name==="rent"?(rentalOption === "daily"
        ? opt.rentalrate
        : rentalOption === "weekly"
        ? opt.weeklyrentalrate
        : rentalOption === "monthly"
        ? opt.monthlyrentalrate
        : opt.rentalrate) :opt.additional_price) : productDetail.price,
      description: productDetail.description,
      quantity: productDetail.quantity,
      subtotal: extra_option
        ? parseFloat(productDetail.quantity *(name==="rent"?(rentalOption === "daily"
          ? opt.rentalrate
          : rentalOption === "weekly"
          ? opt.weeklyrentalrate
          : rentalOption === "monthly"
          ? opt.monthlyrentalrate
          : opt.rentalrate) :opt.additional_price))
        : productDetail.quantity * productDetail.price,
      image: productDetail.image,
      has_extra_option: productDetail.has_extra_option,
      type:productDetail.type
    };
    console.log(newProduct);

    dispatch(addCartProducts([newProduct]));
  };

  const CartView = () => {
    navigate("/viewcart");
  };
  const getCartQuantity = (productId) => {
    console.log(productId);
    console.log("cart in getcartquantity", viewCart);
    const item = viewCart.find(
      (item) => parseInt(item._id) === parseInt(productId)
    );
    console.log(item);
    return item ? item.quantity : 0;
  };

  const handleIncrement = (productId, product) => {
    const item = viewCart.find(
      (item) => parseInt(item._id) === parseInt(productId)
    );
    const qty = item ? item.quantity : 0;
    dispatch(incrementProduct({ _id: productId, quantity: qty + 1 ,type:product.type}));
  };

  const handleDecrement = (productId, product) => {
    const item = viewCart.find(
      (item) => parseInt(item._id) === parseInt(productId)
    );
    if (item && item.quantity > 1) {
      const qty = item.quantity - 1;
      dispatch(decrementProduct({ _id: productId, quantity: qty ,type:product.type}));
    } else if (item.quantity == 1) {
      const qty = item.quantity - 1;
      dispatch(removeItem({_id:productId,type:product.type}));
    }
  };
  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
  };
  if (status == "loading")
    return (
      <Container className="text-center">
        <Loader loading={status} color="#36d7b7" />{" "}
      </Container>
    );
  if (status == "failed")
    return (
      <Container className="text-center">
        <Loader loading={status} color="#FF0000" /> Error: {status}
      </Container>
    );

  const handleImageLoad = () => {
    setLoading(false); // Once image is loaded, set loading state to false
    setImageLoaded(true); // Set imageLoaded to true
  };
  const handleImageError = () => {
    setLoading(false); // Once image is loaded, set loading state to false
    setImageLoaded(true); // Set imageLoaded to true
  };
  console.log(productDetail);

  return (
    <Container fluid className="p-1 mt-3 ">
      <Card className="border border-light ">
        <Row className="m-2">
          <Col md={4}>
            {loading == "false" ? (
              <Loader loading={imageLoaded} />
            ) : (
              <Image
                src={IMAGE_BASEURL + productDetail.image} // Correctly accessing properties of productDetail
                className="img-fluid   rounded-border"
                alt={productDetail.image}
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
            )}
          </Col>
          <Col md={8} className="mt-3 text-left">
            <>
              <Row>
                <Col>
                  <h4 className=" fw-bolder">{productDetail.name}</h4>
                </Col>
                <Col className="text-end">
                {name==="sale"?(
                  <div className="fw-bold text-danger me-3">
                    <CurrencyDisplay
                      amount={productDetail.price}
                      largeFont={true}
                    />
                  </div>):<></>}
                
                </Col>
              </Row>

              <p className="card-text">{productDetail.description}</p>
              {productDetail.has_extra_option === true ? (
                <>
                  {productDetail.options.map((option) => (
                    <Form.Group key={option.id} controlId={option.id}>
                      <Row>
                        <Col>
                          <Form.Label className="text-dark">
                            {option.option_name}
                          </Form.Label>
                        </Col>
                        <Col>
                          <Form.Check
                            className="custom-radio"
                            type="radio"
                            id={option.id.toString()}
                            name="radioGroup"
                            defaultChecked
                            checked={selectedOption == option.id.toString()}
                            value={option.id}
                            label={name==="rent"?
                              (rentalOption === "daily"
                                ? option.rentalrate
                                : rentalOption === "weekly"
                                ? option.weeklyrentalrate
                                : rentalOption === "monthly"
                                ? option.monthlyrentalrate
                                : option.rentalrate
                  ):option.additional_price}
                            onChange={handleSelect}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  ))}
                  {/* {selectedOption && <p>You selected: {selectedOption}</p>} */}
                </>
              ) : (
                <></>
              )}
              {name === "rent" ? (
                <>
                  <Row id="rentoptions">
                    <Col md={8}>
                      <h4 className=" fw-bolder">Rent Options</h4>
                      <ButtonGroup className="mb-3" aria-label="Rental Options">
                        <Button
                          variant={
                            rentalOption === "daily"
                              ? "danger"
                              : "outline-danger"
                          }
                          onClick={(event) => handleRentalOptionChange("daily",event)}
                        >
                          Daily
                        </Button>
                        <Button
                          variant={
                            rentalOption === "weekly"
                              ? "danger"
                              : "outline-danger"
                          }
                          onClick={(event) => handleRentalOptionChange("weekly",event)}
                        >
                          Weekly
                        </Button>
                        <Button
                          variant={
                            rentalOption === "monthly"
                              ? "danger"
                              : "outline-danger"
                          }
                          onClick={(event) => handleRentalOptionChange("monthly",event)}
                        >
                          Monthly
                        </Button>

                        <Button
                          variant={
                            rentalOption === "custom"
                              ? "danger"
                              : "outline-danger"
                          }
                          onClick={() => handleRentalOptionChange("custom")}
                        >
                          Custom
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  {rentalOption === "custom" ? (
                    <Form>
                      <Form.Group controlId="startDate">
                        <Form.Label>Start Date</Form.Label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                          dateFormat="dd/MM/yyyy" // Correct date format
                          placeholderText="Select start date"
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          isClearable
                        />
                      </Form.Group>

                      <Form.Group controlId="endDate" className="mt-2">
                        <Form.Label>End Date</Form.Label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          className="form-control"
                          dateFormat="dd/MM/yyyy" // Correct date format
                          placeholderText="Select end date"
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          isClearable
                        />
                      </Form.Group>
                    </Form>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {getCartQuantity(
                selectedOption ? selectedOption : productDetail.productid
              ) === 0 ? (
                <div>
                  <div>
                    <button
                      className="btn btn-outline-danger fw-bold m-2"
                      onClick={addtoCart}
                    >
                      Add to cart
                    </button>
                  </div>
                </div>
              ) : (
                <Col lg="auto text-left  ">
                  <div className="m-2 btn d-flex fs-6 fw-bold text-danger border-white text-left">
                    <span className="fs-6 fw-bold text-dark me-3">
                      Quantity
                    </span>
                    <div className="assistant-font icon-wrapper">
                      <FontAwesomeIcon
                        size="sm"
                        icon={faMinus}
                        onClick={() =>
                          handleDecrement(
                            productDetail.has_extra_option === true
                              ? selectedOption
                              : productDetail.productid,
                            productDetail
                          )
                        }
                        className="custom-icon"
                      />
                    </div>

                    <span
                      bg="danger"
                      className="mx-2 assistant-font fs-5 fw-bold mb-2"
                    >
                      {getCartQuantity(
                        selectedOption
                          ? selectedOption
                          : productDetail.productid
                      )}
                    </span>
                    <div className="assistant-font icon-wrapper">
                      <FontAwesomeIcon
                        size="sm"
                        className="custom-icon"
                        icon={faPlus}
                        onClick={() =>
                          handleIncrement(
                            productDetail.has_extra_option === true
                              ? selectedOption
                              : productDetail.productid,
                            productDetail
                          )
                        }
                      />
                    </div>
                  </div>
                </Col>
              )}
              <div>
                <button
                  className="btn btn-danger border-red fw-bold m-2"
                  type="button"
                  onClick={() => CartView()}
                >
                  View Cart
                </button>
              </div>
            </>
          </Col>
        </Row>
      </Card>
      <Container className="mt-4 p-3 "></Container>
    </Container>
  );
}

export default ProductDetailPage;
