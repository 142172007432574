export const BASE_URL="https://admin.humaidbrand.com/client/api/json/v1/1";
//export const BASE_URL="https://51.20.83.24:8000/client/api/json/v1/1";
//export const BASE_URL="https://adpizzamodo.elixirofo.com/client/api/json/v1/1";
export const IMAGE_BASEURL="";

// export const data = [
//   { id: 1, name: 'Item 1', description: 'Description of Item 1', imageUrl: '/images/Construction-Machinery.jpg' },
//   { id: 2, name: 'Item 2', description: 'Description of Item 2', imageUrl: 'https://example.com/image2.jpg' },
//   { id: 3, name: 'Item 3', description: 'Description of Item 3', imageUrl: 'https://example.com/image3.jpg' }
// ];

export const bannerData=[
    {   "id":"1",
        "image": "/images/rentaleqipment/rentaleqipment/banner/Compct-machinery-equipment-Wacker-Mob.png"
    },
    {  "id":"2",
        "image": "/images/rentaleqipment/rentaleqipment/banner/Fork-lift-Yale-Mob.png"
    },
];
export const categoryEquipmentData=[
    {
        "id": 1,
        "name": "Trucks & Buses",
        "sub_name": "",
        "description": "For over the years, we have been one of the trusted names in transport solutions, progressively being a market leader for both trucks and buses in the region with strong alliance with our Group Company; Darwish Bin Ahmed & Sons Co. LLC, who are the exclusive dealer for “MAN Trucks & Bus AG” – Germany, for the entire UAE.",
        "image": "/images/rentaleqipment/rentaleqipment/categories/Truck-Bus.jpg"
    },
    {
        "id": 2,
        "name": "Tyres",
        "sub_name": "",
        "description": "The Tyre division was established in 2005 to primarily fulfill the requirements of group companies when it comes to quality and cost-effective products. We offer efficient and customized service and solutions to ensure that our products are well-maintained to provide maximum longevity.",
        "image": "/images/rentaleqipment/rentaleqipment/categories/Tyres.jpg"
    },
    {
        "id": 3,
        "name": "Construction Machinery",
        "sub_name": "",
        "description": "Construction Machineries specially designed to handle various construction operations such as Excavation, Loading, Hauling, Grading, Compaction and Paving. United Motors and Heavy Equipment co LLC will be one stop solution for all your Construction machinery needs to execute Earth works, Loading, Mining, Building, Road and Concrete constructions.",
        "image": "/images/rentaleqipment/rentaleqipment/categories/Construction-Machinery.jpg"
    },
    {
        "id": 4,
        "name": "Mining",
        "sub_name": "",
        "description": "Mining entails the retrieval of aggregates, minerals, and metals from deposits on the land. In the UAE, Gabbro and Limestone mining stand out as the predominant mining activities. Mining equipment, meticulously engineered to streamline diverse mining operations, proves instrumental in reducing operational expenses and achieving cost-efficient production on a per-ton basis.",
        "image": "/images/rentaleqipment/rentaleqipment/categories/Mining.jpg"
    },
    {
        "id": 5,
        "name": "Forklifts & Cranes",
        "sub_name": "",
        "description": "Yale is one of the original manufacturers of forklift trucks in the world, having been in the business of materials handling for nearly 140 years. Yale has an unrivalled heritage in the design and manufacture of electric, diesel, LPG and CNG-powered counterbalance forklift trucks and warehouse equipment and is credited with introducing the first electric powered lift truck into the European market in 1919. ",
        "image": "/images/rentaleqipment/rentaleqipment/categories/Forklifts-Cranes.jpg"
    },
    {
        "id": 6,
        "name": "Generators & Compressors",
        "sub_name": "",
        "description": "United Motors & Heavy Equipment are Authorized Distributors for the below brands of Generators and Compressors.",
        "image": "/images/rentaleqipment/rentaleqipment/categories/Generator-Compressor.jpg"
    },
    {
        "id": 7,
        "name": "Tower Lights",
        "sub_name": "",
        "description": "Do you need lighting for your construction site or work area? Are you planning on lighting an outdoor event? A light tower is a well-designed piece of equipment that’s focus is to offer lighting that is adaptable and efficient. A tower light is a piece of mobile equipment which has one or more high-intensity electric lamps and a mast. Almost always, the lights are attached to the mast, which is attached to a trailer, with a generator set to power the lamps.",
        "image": "/images/rentaleqipment/rentaleqipment/categories/Tower-Lights.jpg"
    }
]
export const categoryServicetData = [
    {
        "id": 1,
        "name": "Rent Property",
        "sub_name": "Houses,Flats,Rooms,Offices,Shops",
        "description": "Our transport solutions are trusted for their reliability and leadership in the market. Partnered with Darwish Bin Ahmed & Sons Co. LLC, the exclusive dealer for MAN Trucks & Bus AG in the UAE, we offer top-quality trucks and buses. Prices start at $500. Contact us for specific details based on model and specifications.",
        "price": "500",
        "image": "/images/rentaleqipment/rentaleqipment/categories/rant.jpg"
    },
    {
        "id": 2,
        "name": "Design 2D",
        "sub_name": "House,multi floors building.interior design,exterior landscape",
        "description": "Established in 2005, our Tyre division specializes in providing high-quality, cost-effective tyres and maintenance solutions. Prices start at $500. For specific product details and pricing, please contact us.",
        "price": "500",
        "image": "/images/rentaleqipment/rentaleqipment/categories/design.jpg"
    },
    {
        "id": 3,
        "name": "Design 3D",
        "sub_name": "House,multi floors building.interior design,exterior landscape",
        "description": "Established in 2005, our Tyre division specializes in providing high-quality, cost-effective tyres and maintenance solutions. Prices start at $500. For specific product details and pricing, please contact us.",
        "price": "500",
        "image": "/images/rentaleqipment/rentaleqipment/categories/design.jpg"
    },
    {
        "id": 4,
        "name": "Consulting",
        "sub_name": "",
        "description": "We offer expert consulting for construction machinery, covering excavation, loading, hauling, grading, compaction, and paving. Our services start at $500. Contact us to tailor a consulting package to your needs and get a detailed quote.",
        "price": "500",
        "image": "/images/rentaleqipment/rentaleqipment/categories/consulting.jpg"
    },
    {
        "id": 5,
        "name": "Construction",
        "sub_name": "",
        "description": "Specialized in mining equipment for aggregates, minerals, and metals, we provide tools engineered for efficiency and cost-effectiveness. Prices start at $500. Reach out for more information on specific equipment and pricing.",
        "price": "500",
        "image": "/images/rentaleqipment/rentaleqipment/categories/construction.jpg"
    },
    {
        "id": 6,
        "name": "Fitout Work",
        "sub_name": "",
        "description": "Yale's forklifts, with a history spanning over 140 years, are renowned for their durability and innovation. Pricing for our forklifts starts at $500. Contact us for details on specific models and their prices.",
        "price": "500",
        "image": "/images/rentaleqipment/rentaleqipment/categories/fitoutwork.jpg"
    },
    {
        "id": 6,
        "name": "Labour Supply",
        "sub_name": "",
        "description": "We are authorized distributors for leading brands of generators and compressors. Pricing starts at $500. For detailed pricing and equipment options, please get in touch with us.",
        "price": "500",
        "image": "/images/rentaleqipment/rentaleqipment/categories/laboursupply.jpg"
    },
    {
        "id": 7,
        "name": "Loading , Unloading & Cleaning Services",
        "sub_name": "",
        "description": "Our light towers are ideal for construction sites and outdoor events, offering adaptable and efficient lighting solutions. Prices start at $500. Contact us for a quote tailored to your needs and the specifics of the light tower.",
        "price": "500",
        "image": "/images/rentaleqipment/rentaleqipment/categories/loadingunloading.jpg"
    }
];

export const servicesData=[
    {
        "id": 1,
        "name": "Sales",
        "description": "For over the years, we have been one of the trusted names in transport solutions, progressively being a market leader for both trucks and buses in the region with strong alliance with our Group Company; Darwish Bin Ahmed & Sons Co. LLC, who are the exclusive dealer for “MAN Trucks & Bus AG” – Germany, for the entire UAE.",
        "image": "/images/rentaleqipment/rentaleqipment/categories/Truck-Bus.jpg"
    },
    {
        "id": 2,
        "name": "Rental",
        "description": "The Tyre division was established in 2005 to primarily fulfill the requirements of group companies when it comes to quality and cost-effective products. We offer efficient and customized service and solutions to ensure that our products are well-maintained to provide maximum longevity.",
        "image": "/images/rentaleqipment/rentaleqipment/categories/Tyres.jpg"
    },
    {
        "id": 3,
        "name": "Services",
        "description": "Construction Machineries specially designed to handle various construction operations such as Excavation, Loading, Hauling, Grading, Compaction and Paving. United Motors and Heavy Equipment co LLC will be one stop solution for all your Construction machinery needs to execute Earth works, Loading, Mining, Building, Road and Concrete constructions.",
        "image": "/images/rentaleqipment/rentaleqipment/categories/Construction-Machinery.jpg"
    },
  
]
export const rentalData=[
    {
        "id": 1,
        "name": "Car",
        "sub_name": "car",
        "description": "For over the years, we have been one of the trusted names in transport solutions, progressively being a market leader for both trucks and buses in the region with strong alliance with our Group Company; Darwish Bin Ahmed & Sons Co. LLC, who are the exclusive dealer for “MAN Trucks & Bus AG” – Germany, for the entire UAE.",
        "image": "/images/rentaleqipment/rentaleqipment/categories/cars2.jpeg"
    },
    {
        "id": 2,
        "name": "Building",
        "sub_name": "building",
        "description": "The Tyre division was established in 2005 to primarily fulfill the requirements of group companies when it comes to quality and cost-effective products. We offer efficient and customized service and solutions to ensure that our products are well-maintained to provide maximum longevity.",
        "image": "/images/rentaleqipment/rentaleqipment/categories/buildings.jpeg"
    },
    {
        "id": 3,
        "name": "Equipment",
        "sub_name": "equipment",
        "description": "Construction Machineries specially designed to handle various construction operations such as Excavation, Loading, Hauling, Grading, Compaction and Paving. United Motors and Heavy Equipment co LLC will be one stop solution for all your Construction machinery needs to execute Earth works, Loading, Mining, Building, Road and Concrete constructions.",
        "image": "/images/rentaleqipment/rentaleqipment/categories/equipments.jpeg"
    },
  
]

export const productData=[
    {
        "id": 1,
        "options": [
            {
                "id": 1,
                "option_name": "MAN TGS",
                "additional_price": "2500",
                "stock_quantity": 0,
                "product": 1
            },
            {
                "id": 2,
                "option_name": "MAN TGM",
                "additional_price": "3500",
                "stock_quantity": 0,
                "product": 1
            },
            {
                "id": 3,
                "option_name": "MAN TGL",
                "additional_price": "4500",
                "stock_quantity": 0,
                "product": 1
            }
        ],
        "name": "Used Man Trucks",
        "description": "MAN Truck & Bus is one of the world’s leading commercial vehicle manufacturers and has an extensive portfolio of Trucks ( Used Man Trucks & Used Man Trucks) and Buses.",
        "price": "2500",
        "image": "/images/rentaleqipment/rentaleqipment/products/truck&buses/Man_truck_thumb.jpg",
        "has_extra_option": true,
        "is_popular": true,
        "category": 1
    },
{
        "id": 2,
        "options": [
            {
                "id":4,
                "option_name": "MAN Lion's City",
                "additional_price": "2500",
                "stock_quantity": 0,
                "product": 2
            },
            {
                "id": 5,
                "option_name": "MAN Lion's Coach",
                "additional_price": "3500",
                "stock_quantity": 0,
                "product": 2
            },
           
        ],
        "name": "Used MAN Buses",
        "description": "MAN Truck & Bus is one of the world’s leading commercial vehicle manufacturers and has an extensive portfolio of Trucks ( Used Trucks & Used Man Trucks) and Buses.",
        "price": "2500",
       "image": "/images/rentaleqipment/rentaleqipment/products/truck&buses/Man_bus_thumb.jpg",
        "has_extra_option": true,
        "is_popular": true,
        "category": 1
    },
{
        "id": 3,
        "options": [
            {
                "id":6,
                "option_name": "Light Truck Tyres",
                "additional_price": "2500",
                "stock_quantity": 0,
                "product":3
            },
            {
                "id": 7,
                "option_name": "Truck & Bus Tyres",
                "additional_price": "3500",
                "stock_quantity": 0,
                "product":3
            },
           
        ],
        "name": "High Way Tyres",
        "description": "MAN Truck & Bus is one of the world’s leading commercial vehicle manufacturers and has an extensive portfolio of Trucks ( New Trucks & New Man Trucks) and Buses.",
        "price": "2500",
        
        "image": "/images/rentaleqipment/rentaleqipment/products/tyres/highway_tyres.png",
        "has_extra_option": true,
        "is_popular": true,
        "category": 2
    },
{
        "id": 4,
        "options": [
            {
                "id":8,
                "option_name": "Medium &Large",
                "additional_price": "2500",
                "stock_quantity": 0,
                "product":4
            },
            {
                "id": 9,
                "option_name": "Mini&Compact",
                "additional_price": "3500",
                "stock_quantity": 0,
                "product":4
            },
           
        ],
        "name": "New Excavators",
        "description": "MAN Truck & Bus is one of the world’s leading commercial vehicle manufacturers and has an extensive portfolio of Trucks ( New Trucks & New Man Trucks) and Buses.",
        "price": "2500",
        "image": "/images/rentaleqipment/rentaleqipment/products/construction-nmachinery/sany-large-excavator.jpg",
        //"image": "D:\rentaleqipment\products\construction-nmachinery\sany-large-excavator.jpg",
        "has_extra_option": true,
        "is_popular": true,
        "category": 3
    },
    {
        "id": 5,
        "options": [
            {
                "id":8,
                "option_name": "Medium &Large",
                "additional_price": "2500",
                "stock_quantity": 0,
                "product":4
            },
            {
                "id": 9,
                "option_name": "Mini&Compact",
                "additional_price": "3500",
                "stock_quantity": 0,
                "product":4
            },
           
        ],
        "name": "Used Excavators",
        "description": "MAN Truck & Bus is one of the world’s leading commercial vehicle manufacturers and has an extensive portfolio of Trucks ( New Trucks & New Man Trucks) and Buses.",
        "price": "2500",
        "image": "/images/rentaleqipment/rentaleqipment/products/construction-nmachinery/sany-large-excavator.jpg",
        //"image": "D:\rentaleqipment\products\construction-nmachinery\sany-large-excavator.jpg",
        "has_extra_option": true,
        "is_popular": true,
        "category": 3
    },
    
    ]
// export const productData=[
//     {
//         "id": 1,
        
//         "name": "NEW MAN TRUCKS",
//         "description": "Mozzarella Cheese, Cheddar Cheese, Chopped Garlic, Oregano, Olive Oil (Thin Crust Only)",
//         "price": "2500",
//         "image": "/images/rentaleqipment/rentaleqipment/products/truck&buses/Man_truck_thumb.jpg",
//         "has_extra_option": false,
//         "is_popular": true,
//         "category": 1,
        
//     },
//    {
//         "id": 2,
        
//         "name": "NEW MAN BUSES",
//         "description": "Mozzarella Cheese, Cheddar Cheese, Chopped Garlic, Oregano, Olive Oil (Thin Crust Only)",
//         "price": "2500",
//         "image": "/images/rentaleqipment/rentaleqipment/products/truck&buses/Man_bus_thumb.jpg",
//         "has_extra_option": false,
//         "is_popular": true,
//         "category": 1
//     },
//  {
//         "id": 3,
        
//         "name": "MAN Top Used",
//         "description": "Mozzarella Cheese, Cheddar Cheese, Chopped Garlic, Oregano, Olive Oil (Thin Crust Only)",
//         "price": "2500",
//         "image": "/images/rentaleqipment/rentaleqipment/products/truck&buses/Man_bus_thumb.jpg",
//         "has_extra_option": false,
//         "is_popular": true,
//         "category": 1
//     },
// {
//         "id": 4,
        
//         "name": "High way tyres",
//         "description": "Mozzarella Cheese, Cheddar Cheese, Chopped Garlic, Oregano, Olive Oil (Thin Crust Only)",
//         "price": "2500",
//         "image": "/images/rentaleqipment/rentaleqipment/products/tyres/highway_tyres.png",
//         "has_extra_option": false,
//         "is_popular": true,
//         "category": 2
//     },
// {
//         "id": 4,     
//         "name": "Off way tyres",
//         "description": "Mozzarella Cheese, Cheddar Cheese, Chopped Garlic, Oregano, Olive Oil (Thin Crust Only)",
//         "price": "2500",
//         "image": "/images/rentaleqipment/rentaleqipment/products/tyres/Offway_tyres.png",
//         "has_extra_option": false,
//         "is_popular": true,
//         "category": 2
//     },
//    ]

   export const productdetailData=
    {
        "id": 1,
        "options": [
            {
                "id": 1,
                "option_name": "MAN TGS",
                "additional_price": "2.500",
                "stock_quantity": 0,
                "product": 35
            },
            {
                "id": 2,
                "option_name": "MAN TGM",
                "additional_price": "3.500",
                "stock_quantity": 0,
                "product": 35
            },
            {
                "id": 3,
                "option_name": "MAN TGL",
                "additional_price": "4.500",
                "stock_quantity": 0,
                "product": 35
            }
        ],
        "name": "New MAN Trucks",
        "description": "MAN Truck & Bus is one of the world’s leading commercial vehicle manufacturers and has an extensive portfolio of Trucks ( New Trucks & New Man Trucks) and Buses.",
        "price": "2500",
        "image": "/images/rentaleqipment/rentaleqipment/products/truck&buses/Man_truck_thumb.jpg",
        "has_extra_option": true,
        "is_popular": true,
        "category": 1
    }
    

