import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL, productData, productdetailData } from "../../../../data/Constants";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Button,
  Form,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCartProducts,
  incrementProduct,
  decrementProduct,
  getCartQuantity,
  removeItem,
} from "../../../cart/reducer/CartReducer";
import { IMAGE_BASEURL } from "../../../../data/Constants";
import CurrencyDisplay from "../../../settings/component/Currency";
import { fetchProductDetailByID } from "../../../thunks/Thunks";
import Loader from "../../../../components/loader/Loader";
import { faPlusMinus } from "@fortawesome/free-solid-svg-icons";
//import { handleDecrement, handleIncrement } from "../app/utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  faCartPlus,
  faTrash,
  faMinus,
  faPlus,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import Badge from "react-bootstrap/Badge";

function RentOptionDetailPage() {
  const { id,name } = useParams();
  console.log(id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewCart = useSelector((state) => state.cart.viewCart);
  const data = useSelector((state) => state.productdetail.data);
  const [status, setStatus] = useState("loading");
  const [selectedOption, setSelectedOption] = useState();
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const Url = `${BASE_URL}/product-option/${id}/`;
  console.log(Url);
 

  const [rentalOption, setRentalOption] = useState("daily"); // Default to weekly

 
  const [productDetail, setProductDetail] = useState({
    id: 0,
    productid: 0,
    category: "",
    option_name: "",
    productname: "",
    description: "",
    additional_price: "",
    image: "",
    has_extra_option: true,
    type:"",
  });
  //useeffect fetch product based on the id from useparams
  // const detail =productData.filter(product => product.id === parseInt(id));
  // console.log(detail[0]);
  // const newdata = detail[0];
  useEffect(() => {
    console.log(Url);
    dispatch(fetchProductDetailByID(id));

    axios
      .get(Url)
      .then((response) => {
        console.log(response.data);
        const newdata = response.data;
        // const newdata = productdetailData;

        console.log(newdata);
        // setProductata(response.data.data);
        setProductDetail({
          ...productDetail,
          id: newdata.id,
          productid: newdata.id,
          name: newdata.option_name,
          productname: newdata.option_name,
          category: JSON.stringify(newdata.product.category),
          description: newdata.description,
          image: newdata.image,
          price: newdata.additional_price,
          quantity: 1,
          subtotal: parseFloat(1 * newdata.additional_price),
          has_extra_option: newdata.has_extra_option,
          make: newdata.make,
          model: newdata.model,
          year: newdata.year,
          mileage: newdata.mileage,
          image: newdata.image,
          type:"rent",   
        });

        // setSelectedOption(
        //   newdata.has_extra_option == true ? newdata.options[0].id : newdata.id
        // );
        setSelectedOption(id);

        setStatus("success");
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setStatus("failed");
          console.error(
            "Network error: Please check your internet connection."
          );
        } else {
          setStatus("failed");
          console.error("Error:", error.message);
        }
      });
  }, [id]);
  console.log(productDetail);
  const addtoCart = (event) => {
    event.preventDefault();
    
    console.log(rentalOption);
    const newProduct = {
      _id:  productDetail.id,
      productid: productDetail.id,
      name: productDetail.name,
      productname: productDetail.name,
      category: productDetail.category,
      price: productDetail.price,
      description: productDetail.description,
      quantity: productDetail.quantity,
      subtotal: parseFloat(productDetail.quantity *productDetail.price),
      image: productDetail.image,
      type:productDetail.type,
    };
    console.log(newProduct);

    dispatch(addCartProducts([newProduct]));
  };

  const CartView = () => {
    navigate("/viewcart");
  };
  const getCartQuantity = (productId) => {
    console.log(productId);
    console.log("cart in getcartquantity", viewCart);
    const item = viewCart.find(
      (item) => parseInt(item._id) === parseInt(productId)
    );
    console.log(item);
    return item ? item.quantity : 0;
  };

  const handleIncrement = (productId, product) => {
    console.log(product);
    const item = viewCart.find(
      (item) => parseInt(item._id) === parseInt(productId)
    );
    const qty = item ? item.quantity : 0;
    dispatch(incrementProduct({ _id: productId, quantity: qty + 1 ,type:product.type}));
  };

  const handleDecrement = (productId, product) => {
    const item = viewCart.find(
      (item) => parseInt(item._id) === parseInt(productId)
    );
    if (item && item.quantity > 1) {
      const qty = item.quantity - 1;
      dispatch(decrementProduct({ _id: productId, quantity: qty,type:product.type }));
    } else if (item.quantity == 1) {
      const qty = item.quantity - 1;
      dispatch(removeItem({_id:productId,type:product.type}));
    }
  };
  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
  };
  if (status == "loading")
    return (
      <Container className="text-center">
        <Loader loading={status} color="#36d7b7" />{" "}
      </Container>
    );
  if (status == "failed")
    return (
      <Container className="text-center">
        <Loader loading={status} color="#FF0000" /> Error: {status}
      </Container>
    );

  const handleImageLoad = () => {
    setLoading(false); // Once image is loaded, set loading state to false
    setImageLoaded(true); // Set imageLoaded to true
  };
  const handleImageError = () => {
    setLoading(false); // Once image is loaded, set loading state to false
    setImageLoaded(true); // Set imageLoaded to true
  };
  console.log(productDetail);

  return (
    <Container fluid className="p-1 mt-3 ">
      <Card className="border border-light ">
        <Row className="m-2">
          <Col md={4}>
            {loading == "false" ? (
              <Loader loading={imageLoaded} />
            ) : (
              <Image
                src={IMAGE_BASEURL + productDetail.image} // Correctly accessing properties of productDetail
                className="img-fluid   rounded-border"
                alt={productDetail.image}
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
            )}
          </Col>
          <Col md={8} className="mt-3 text-left">
            <>
              <Row>
                <Col>
                  <h4 className=" fw-bolder">{productDetail.name}</h4>
                </Col>
                <Col className="text-end">
                {name==="sale"?(
                  <div className="fw-bold text-danger me-3">
                    <CurrencyDisplay
                      amount={productDetail.price}
                      largeFont={true}
                    />
                  </div>):<></>}
                
                </Col>
              </Row>

              <p className="card-text">{productDetail.description}</p>
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Vehicle Information</h2>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <strong>Make:</strong> {productDetail.make}
                    </li>
                    <li className="list-group-item">
                      <strong>Model:</strong> {productDetail.model}
                    </li>
                    <li className="list-group-item">
                      <strong>Year:</strong> {productDetail.year}
                    </li>
                    <li className="list-group-item">
                      <strong>Mileage:</strong> {productDetail.mileage} miles
                    </li>
                    <li className="list-group-item">
                      <strong>price:</strong> {productDetail.price}
                    </li>
                  </ul>
                </div>
              </div>



              {getCartQuantity(
               productDetail.productid
              ) === 0 ? (
                <div>
                  <div>
                    <button
                      className="btn btn-outline-danger fw-bold m-2"
                      onClick={addtoCart}
                    >
                      Add to cart
                    </button>
                  </div>
                </div>
              ) : (
                <Col lg="auto text-left  ">
                  <div className="m-2 btn d-flex fs-6 fw-bold text-danger border-white text-left">
                    <span className="fs-6 fw-bold text-dark me-3">
                      Quantity
                    </span>
                    <div className="assistant-font icon-wrapper">
                      <FontAwesomeIcon
                        size="sm"
                        icon={faMinus}
                        onClick={() =>
                          handleDecrement(
                    productDetail.productid,
                            productDetail
                          )
                        }
                        className="custom-icon"
                      />
                    </div>

                    <span
                      bg="danger"
                      className="mx-2 assistant-font fs-5 fw-bold mb-2"
                    >
                      {getCartQuantity(
                   productDetail.productid
                      )}
                    </span>
                    <div className="assistant-font icon-wrapper">
                      <FontAwesomeIcon
                        size="sm"
                        className="custom-icon"
                        icon={faPlus}
                        onClick={() =>
                          handleIncrement(
                      productDetail.productid,
                            productDetail
                          )
                        }
                      />
                    </div>
                  </div>
                </Col>
              )}
              <div>
                <button
                  className="btn btn-danger border-red fw-bold m-2"
                  type="button"
                  onClick={() => CartView()}
                >
                  View Cart
                </button>
              </div>
            </>
          </Col>
        </Row>
      </Card>
      <Container className="mt-4 p-3 "></Container>
    </Container>
  );
}

export default RentOptionDetailPage;
