import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter, Route, Router } from "react-router-dom";
import Search from "./components/search/Search";
import Banner from "./components/banner/Banner";
import Categories from "./features/categories/components/Categories";
import { useState } from "react";

import { Routes } from "react-router-dom";
import HomePage from "./layouts/HomePage";
import Header from "./components/header/Headers";
import Headers from "./components/header/Headers";
import UserDetail from "./layouts/UserDetail";
import CartPage from "./features/cart/CartPage";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { loadCartFromLocalStorage } from "./features/cart/reducer/CartReducer";
import OrderSummaryPage from "./layouts/OrderSummaryPage";
import { useSelector } from "react-redux";
import MapComponent from "./components/map/MapComponent";
import './styles/Custom.css'; // Import the SCSS file
import ThankYouOrder from "./layouts/ThankYouOrder";
import FloatingCartIcon from "./components/floatingcart/FloatingCartIcon";
import PlacesAutoComplete from "./features/map/component/PlacesAutoComplete";
import CategoryDetails from "./features/categories/components/CategoryDetails";
import Footer from "./components/footer/Footer";
import PopularProducts from "./features/products/components/PopularProducts";
import AboutUs from "./layouts/AboutUs";
import { fetchSettingsApi } from "./features/thunks/Thunks";
import ContactUs from "./layouts/ContactUs";
import UserDetail2 from "./layouts/UserDetail2";
import Products from "./features/products/components/Products";
import ServiceDetails from "./features/categories/components/services/ServiceDetails";
import MaterialDetails from "./features/categories/components/materials/MaterialDetails";
import RentProductsByCategory from "./features/categories/components/rent/RentProductsByCategory";
import RentDetailPage from "./features/categories/components/rent/RentDetailPage";
import RentSubCategories from "./features/categories/components/rent/RentSubProducts";
import RentSubProducts from "./features/categories/components/rent/RentSubProducts";
import SaleSubProducts from "./features/products/components/SaleSubProducts";
import ProductDetailPage from "./features/products/components/ProductDetailPage";
import ProductOptionDetailPage from "./features/products/components/ProductOptionDetails";
import RentOptionDetailPage from "./features/categories/components/rent/RentOptionDetail";
import BuildingDetails from "./features/categories/components/buildingmgmnt/BuildingDetail";
import RentOptionProducts from "./features/categories/components/rent/RentOptionProduct";


function App() {
  const dispatch = useDispatch();


  useEffect(() => {
    const cartData = JSON.parse(localStorage.getItem("cart")) || [];
    dispatch(loadCartFromLocalStorage(cartData));
    dispatch(fetchSettingsApi());
  }, [dispatch]);
  const cartItemCount=useSelector((state)=>state.cart.count);

  return (
    <>
      <BrowserRouter>
        <Headers cartCount={cartItemCount}/>
         <FloatingCartIcon itemCount={cartItemCount} /> 
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/popular" element={<PopularProducts />} />
          <Route path="/categorydetail/:id/:name" element={< Products/>} />
          <Route path="/productdetail/:id/:name" element={<ProductDetailPage />} />
          <Route path="/productoptiondetail/:id/:name" element={<ProductOptionDetailPage />} />
          <Route path="/viewcart" element={<CartPage />} />
          {/* <Route path="/adddetail/:option" element={<UserDetail />} /> */}
         <Route path="/adddetail/:option" element={<UserDetail2 />} />
          <Route path="/ordersummary" element={<OrderSummaryPage />} />
          <Route path="/location" element={<PlacesAutoComplete/>}/>
          <Route path="/ordercompleted" element={<ThankYouOrder/>}/>
          <Route path="/all" element={<CategoryDetails/>}/>
          <Route path="/category" element={<Categories/>}/>
          <Route path="/materialdetails/:id" element={<MaterialDetails/>}/>
          <Route path="/buildingdetails/:id/:item" element={<BuildingDetails/>}/>
   
          <Route path="/rentdetail/:id/:option" element={<RentDetailPage/>}/>
          <Route path="/rentoptiondetail/:id" element={<RentOptionDetailPage/>}/>
          <Route path="/servicedetails/:id" element={<ServiceDetails/>}/>
          <Route path="/rentproductsbycategory/:id/:sub_name" element={< RentProductsByCategory/>} />
          <Route path="/rentsubproducts/:id/:name" element={< RentSubProducts/>} />
          <Route path="/rentoptionproducts/:id/:product" element={< RentOptionProducts/>} />
          <Route path="/salesubproducts/:id/:product" element={< SaleSubProducts/>} />
          
        </Routes>
         <Footer itemCount={cartItemCount} /> 
      </BrowserRouter>

    </>
  );
}

export default App;
