import { React, useState, useEffect } from "react";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faTrash,
  faMinus,
  faPlus,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import {
  addCartProducts,
  decrementProduct,
  incrementProduct,
  removeItem,
} from "../../cart/reducer/CartReducer";
import { useDispatch, useSelector } from "react-redux";
import Badge from "react-bootstrap/Badge";
import { IMAGE_BASEURL, productData } from "../../../data/Constants";
import CurrencyDisplay from "../../settings/component/Currency";
import Loader from "../../../components/loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../../styles/Custom.css";
import { Image } from "react-bootstrap";
import defaultImg from "../../../assets/images/defaultimage.jpg";
import { fetchProductsByCategory } from "../../thunks/Thunks";

function Products() {
  const dispatch = useDispatch();
  const { id, name } = useParams();
  console.log(id);
  const products = useSelector((state) => state.product);
  // const products = productData.filter(product => product.category === parseInt(id));

  const viewCart = useSelector((state) => state.cart.viewCart);
  const [hovered, setHovered] = useState({});
  console.log(products);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchProductsByCategory(id));
  }, [id]);

  //_id of listed product,
  //productid another key is kept so that when options are there we need to send options_name and
  const addtoCart = (product) => {
    const newProduct = {
      _id: product.id,
      productid: product.id,
      name: product.name,
      category: product.category,
      price: product.price,
      description: product.description,
      quantity: 1,
      subtotal: 1 * product.price,
      image: product.image,
      has_extra_option: product.has_extra_option,
      options: product.options,
    };
    console.log(newProduct);
    dispatch(addCartProducts([newProduct]));
  };

  const getCartQuantity = (productId) => {
    console.log(productId);
    console.log(products);
    console.log(viewCart);
    const item = viewCart.find((item) => item.productid === productId);
    console.log(item);
    return item ? item.quantity : 0;
  };

  const handleIncrement = (productId, product) => {
    const item = viewCart.find((item) => item._id === productId);
    const qty = item ? item.quantity : 0;
    dispatch(incrementProduct({ _id: productId, quantity: qty + 1 }));
  };

  const handleDecrement = (productId, product) => {
    const item = viewCart.find((item) => item._id === productId);
    if (item && item.quantity > 1) {
      const qty = item.quantity - 1;
      dispatch(decrementProduct({ _id: productId, quantity: qty }));
    } else if (item.quantity == 1) {
      const qty = item.quantity - 1;
      dispatch(removeItem(productId));
    }
  };

  const handleMouseEnter = (id) => {
    setHovered((prevHovered) => ({ ...prevHovered, [id]: true }));
  };

  const handleMouseLeave = (id) => {
    setHovered((prevHovered) => ({ ...prevHovered, [id]: false }));
  };
  //   function splitProductName( ) {
  //     if (product.productName.length > 15) {
  //         let splitIndex = productName.lastIndexOf(' ', maxLength);
  //         if (splitIndex === -1) {
  //             splitIndex = maxLength;
  //         }
  //         let firstPart = product.productName.slice(0, splitIndex);
  //         let secondPart =product.productName.slice(splitIndex).trim();

  //         return { firstPart, secondPart };
  //     } else {
  //         return { firstPart: product.productName, secondPart: '' };
  //     }
  // }
  const splitProductNameByWords = (productName) => {
    const words = productName.split(" ");
    console.log(words.length);
    return words.length;
  };

  return (
    <div className="container-fluid  g-0 ">
      <div className="row g-0 mt-4">
        <h1 className="p-2 fw-bold">{name}</h1>
        {products.filteredData.map((product) => (
          <div className="col-md-3 col-6 p-2   " key={product.id}>
            <Card className="shadow-sm text-left rounded-border-product    ">
              {Array.isArray(product.options) && product.options.length > 0 ? (
                <Link
                  to={`/salesubproducts/${product.id}/${encodeURIComponent(
                    JSON.stringify(product)
                  )}`}
                >
                  <Image
                    src={IMAGE_BASEURL + product.image}
                    alt={defaultImg}
                    className="img-fluid mx-auto p-2 border-white rounded-border-product   "
                  />
                </Link>
              ) : (
                <Link to={`/productdetail/${product.id}/${product.name}`}>
                  <Image
                    src={IMAGE_BASEURL + product.image}
                    alt={defaultImg}
                    className="img-fluid mx-auto p-2 border-white rounded-border-product   "
                  />
                </Link>
              )}

              <Card.Body className="m-0 p-0 pb-2 ">
                <Row className="ps-2 ">
                  <Col>
                    <span className="assistant-font fw-bold fs-6  m-0 truncate ">
                      {product.name}
                    </span>
                    {/* <CurrencyDisplay amount={product.price} /> */}
                  </Col>
                </Row>

                <Row className="justify-content-end">
                  <Col xs="auto  " className="">

                      <>
                        <>
                          <Col xs="col-8 ps-3  ">
                            <div>
                              {product.options &&
                              product.options.length > 0 ? (
                                <Link
                                  to={`/salesubproducts/${
                                    product.id
                                  }/${encodeURIComponent(
                                    JSON.stringify(product)
                                  )}`}
                                  className="text-decoration-none"
                                >
                                  <Button variant="btn btn-outline-danger fs-6 fw-bold m-1 ">
                                    Buy
                                  </Button>
                                </Link>
                              ) : (
                                <Link to={`/productdetail/${product.id}/${product.name}`}   className="text-decoration-none">
                                  <Button variant="btn btn-outline-danger fs-6 fw-bold m-1 ">
                                    Buy
                                  </Button>
                                </Link>
                              )}
                              {/* <Link
                                  to={`/productdetail/${product.id}/rent`}
                                  className="text-decoration-none"
                                >
                                  <Button variant="btn btn-outline-danger fs-6 fw-bold m-1 ">
                                    Rent
                                  </Button>
                                </Link> */}
                            </div>
                          </Col>{" "}
                        </>
                      </>
               
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Products;
