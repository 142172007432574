import { React, useState, useEffect } from "react";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faTrash,
  faMinus,
  faPlus,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import {
  addCartProducts,
  decrementProduct,
  incrementProduct,
  removeItem,
} from "../../../cart/reducer/CartReducer";
import { useDispatch, useSelector } from "react-redux";
import Badge from "react-bootstrap/Badge";
import { IMAGE_BASEURL, productData } from "../../../../data/Constants";
import CurrencyDisplay from "../../../settings/component/Currency";
import Loader from "../../../../components/loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../../../styles/Custom.css";
import { Image } from "react-bootstrap";
import defaultImg from "../../../../assets/images/defaultimage.jpg";
import { fetchProductsByCategory, fetchSubCategoriesByRent, fetchSubProductsByRent } from "../../../thunks/Thunks";
import { fetchProductsByRent } from "../../../thunks/Thunks";
import ContactButton from "../../../../components/contact/Contact";

function RentSubProducts() {
  const dispatch = useDispatch();
  const { id, name } = useParams();
  console.log(id);
  const products = useSelector((state) => state.product);
  console.log(products.rentProductData);

  console.log(products);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchSubProductsByRent(id));
  }, [id]);

  
  return (
    <div className="container-fluid  g-0 ">
      <div className="row g-0 mt-4">
        <h1 className="p-2 fw-bold">{name}</h1>
        {products.rentSubProductData.map((product) => (
          <div
            className="col-md-3 col-6 p-2   "
            key={product.id}
          >
            <Card className="shadow-sm text-left rounded-border-product    ">
            {product.options && product.options.length>0?(

              <Link 
              to={`/rentoptionproducts/${product.id}/${encodeURIComponent(
                      JSON.stringify(product)
                    )}`}>
                <Image
                  src={IMAGE_BASEURL + product.image}
                  alt={defaultImg}
                  className="img-fluid mx-auto p-2 border-white rounded-border-product   "
                />
              </Link>
             ):<>
              <Link
                to={`/rentdetail/${product.id}/nooption`}
              >
                <Image
                  src={IMAGE_BASEURL + product.image}
                  alt={defaultImg}
                  className="img-fluid mx-auto p-2 border-white rounded-border-product   "
                />
              </Link></>}

              <Card.Body className="m-0 p-0 pb-2 ">
                <Row className="ps-2 ">
                  <Col>
                    <span className="assistant-font fw-bold fs-6  m-0 truncate ">
                      {product.make}
                    </span>
                    {/* <CurrencyDisplay amount={product.price} /> */}
                  </Col>
                </Row>
                {product.options && product.options.length>0?(
                <Link
                to={`/rentoptionproducts/${product.id}/${encodeURIComponent(
                  JSON.stringify(product)
                )}`}  className="text-decoration-none">
                 
           
                  <Button variant="btn btn-outline-danger fs-6 fw-bold m-1 ">
                    Rent
                  </Button>
                </Link>
                ):<>  <Link
                to={`/rentdetail/${product.id}/nooption`}
                 className="text-decoration-none"
               >
                 <Button variant="btn btn-outline-danger fs-6 fw-bold m-1 ">
                   Rent
                 </Button>
               </Link></>
              }
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RentSubProducts;
